import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import {numToWords,getIndianCurrency} from 'components/CommonFunctions/utils';

const ProformaInvoice=({setSection, addTokenToState, isLogged, poInfo,setDownloadReportParams,generatePdf })=>{

    const [soDetail,setSoDetail] = useState({});
    const [bankDetailsList, setBankDetailsList] = useState([]);
    const [lineItemChunk,setLinItemChunk] =useState([]);
    const [IGST,setIGST]=useState(false);    
    const [deliveryAddressDetail,setDeliveryAddressDetail] = useState({});
    const [plantProfile,setPlantProfile]=useState()

    useEffect(() => {
        getInformation()
              
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        getSoDetail().then((res)=>{
            if(res.state && res.soDetailId){
              setTimeout(()=>{generatePdf(res.downloadReportParams)},3000)
            }
          });
    }

    const renderImageStatus = () => {
        return (
          <div style={{width:"100px",height: "80px",display:"inline-block"}}>
            <img
              className="vendorHeaderImage" style={{marginLeft: '0px'}}
              src={
                plantProfile !== null && plantProfile !== undefined && plantProfile !== ""
                  ? `data:image/png;base64,${plantProfile}`
                  : null
              }
              alt="Vendor Logo"
            />
            <div className="vendorCurrentStatus">
            </div>
          </div>
        );
      };

    const history = useHistory();

    async function getPlantProfile({plantId}) {
        if (plantId ) {
            var data = await fetchData({
                requestingPage: "plantImage",
                method: "get",
                url: "image/plant-profile-photo/" + plantId,
                headers: { token: isLogged.accessToken, module: "Plant & Store" },
            });
            if (data.msg !== "failure") setPlantProfile(data);
        }
    }

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }
    async function getSoDetail() {
        let {soDetailId} = isLogged
        var data = await fetchData({
          requestingPage: "vendorDetail",
          method: "get",
          url: "so-fetch/so-detail/" + (soDetailId || poInfo.viewSoId),
          headers: { token: isLogged.accessToken, module: "Sales Order" },
        });
        if (data.msg === "success") {
        let deliveryAddCopy = {};
        if(data.asset[0].deliveryAddressId){
            deliveryAddCopy = data.asset[0].deliveryAddressOptions.filter((op)=>op.optionId === data.asset[0].deliveryAddressId)
            deliveryAddCopy = {...deliveryAddCopy[0]}
            deliveryAddCopy.customerName = deliveryAddCopy.companyName
        }else{
            deliveryAddCopy = {...data.asset[0]}
        }

      await getPlantProfile({plantId:data.asset[0].plantId});
    
    setDeliveryAddressDetail(deliveryAddCopy);

    data.asset[0].totalAmount = data.asset[0].totalAmount.toString() 
    let alteredLineItems=data.asset[0].lineItems.map((row)=>{
                                row.CGST = row.CGST==null ? (row.tax)/2 : row.CGST;
                                row.SGST = row.SGST==null ? (row.tax)/2 : row.SGST;
                                row.GST = Number(row.CGST)+Number(row.SGST);
                                row["quantityuom"] = row["quantity"]+" "+row["uom"];
                                if (row["GST"] > 0) {
                                    row["amount"] = (row["netPrice"] * 100) / (100 + row["GST"]);
                                } else {
                                    row["amount"] = row["netPrice"];
                                }
                                row.netPrice = parseFloat(row.netPrice);
                                row.CGSTAmount = ((row["amount"]) * (row.CGST) / 100);
                                row.SGSTAmount = ((row["amount"]) * (row.SGST) / 100);
                                row.taxAmount = (row.CGSTAmount + row.SGSTAmount);
                                row.IGSTAmount = (row.CGSTAmount + row.SGSTAmount);
                                return row;});
    data.asset[0]["CGSTAmount"] = Number(alteredLineItems.reduce((prev,curr)=>prev+curr["CGSTAmount"],0)).toFixed(2)
    data.asset[0]["SGSTAmount"] = Number(alteredLineItems.reduce((prev,curr)=>prev+curr["SGSTAmount"],0)).toFixed(2)
    data.asset[0]["GSTAmount"] = (parseFloat(data.asset[0]["CGSTAmount"]) + parseFloat(data.asset[0]["SGSTAmount"])).toFixed(2)
        setDownloadReportParams({dealerName: data.asset[0].customerName, orderNo: data.asset[0].soNo})
    let lineChunk=[];
    for(let i=0;i< alteredLineItems.length; i+=16){ // 16 items in each page
         lineChunk.push(alteredLineItems.slice(i,i+16));
        }
                    setLinItemChunk(lineChunk);
          setSoDetail(data.asset[0]);
          setBankDetailsList(data.asset[0].bankDetails);
         setDownloadReportParams({dealerName: data.asset[0].customerName, orderNo: data.asset[0].soNo});
          if(data.asset[0].plantState!==data.asset[0].customerState){
            data.asset[0]["IGSTAmount"] =data.asset[0]["GSTAmount"]
            setIGST(true);
        }
        return {state: true,downloadReportParams:{dealerName: data.asset[0].customerName, orderNo: data.asset[0].soNo},soDetailId}
        } else {
          return {state: false,downloadReportParams:""}
        }
      };
      const mainItemsTaxRow=IGST ? ["IGSTAmount","Less RoundedOff"]  :["CGSTAmount","SGSTAmount","Less RoundedOff"]
      if(soDetail.finalDiscount)
       mainItemsTaxRow.push("Less Discount");
       const RenderEmptyRows=({columns, emptyRows})=>{
        return(
             Array.from({length: emptyRows}).map((empty,ind)=>(
                <tr className="invoiceDetailContactsTableRows" key={ind}>
                    <td  style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>
                    {columns.map((key, i) => {
                        return <td  key={i} style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>;
                    })} 
                </tr>)
                )
        )
    }
    const amountInWords = (amount) => {
        if (amount) {
          let result;
          amount = amount.toString().split(".");
          result =
            numToWords(parseInt(amount[0])) +
            (amount[1] > 0
              ? " and " + numToWords(parseInt(amount[1])) + " Paise Only"
              : " Only");
          return result;
        }
      };
       const RenderTaxRow=(colName)=>{

        return(
            <tr className="invoiceDetailContactsTableRows" key={colName}>
                            <td  style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>
                            {lineItemParams.map((key, i) => {
                                switch(key){
                                    case "itemName":
                                        return <td key={i} style={{borderBottom:"none",borderTop:"none",textAlign:"right"}}><b><i>{colName}</i></b></td>
                                    
                                    case "amount":
                                        if(colName==="Less RoundedOff")
                                        {    let roundedOff= soDetail.totalAmount?.split('.')?.[1];
                                            roundedOff = roundedOff || '00'
                                            roundedOff = roundedOff?.toString()?.length > 1 ? roundedOff : roundedOff+'0'
                                        return <td key={i} style={{borderBottom:"none",borderTop:"none",textAlign:"right"}}>{'₹ 00.'+(roundedOff)}</td>
                                        }else if(colName==='Less Discount')
                                        {
                                        return <td key={i} style={{borderBottom:"none",borderTop:"none",textAlign:"right"}}>{soDetail['finalDiscount']}</td>
                                        }
                                        else
                                        return <td key={i} style={{borderBottom:"none",borderTop:"none",textAlign:"right"}}>{getIndianCurrency(soDetail[colName])}</td>
                                    
                                    default:
                                    return <td  key={i} style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>;
                                }
                                })}
                        </tr>
        )
    }
      const RenderTableRows = ({ currentPageLineItems,pageNumber }) => {
        if(currentPageLineItems?.length>0){
        return (<>
            {currentPageLineItems.map((row, j) => {
                return (
                    <tr className="invoiceDetailContactsTableRows" key={j}>
                        <td  style={{borderBottom: "none", borderTop: "none"}}>{((pageNumber-1)*16)+j+1}</td>
                        {lineItemParams.map((key, i) => {
                            if (key === 'amount' || key==="unitPrice") {
                                return <td  style={{borderBottom: "none", borderTop: "none"}} className="align-right" key={i}>{row[key] !== null ? getIndianCurrency(row[key]) : "-"}</td>;
                            } else {
                                return <td  style={{borderBottom: "none", borderTop: "none"}} key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                            }
                        })}
                    </tr>
                );
            })}
            <tr className="invoiceDetailContactsTableRows">
                        {lineItemParams.map((key, i) => {
                               return <td  key={i} style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>;
                         })}
                         <td style={{textAlign:"right",borderBottom:"none"}}>{getIndianCurrency(currentPageLineItems?.reduce((prev,curr)=>prev+curr["amount"],0))}</td>
                    </tr>
            <RenderEmptyRows columns={lineItemParams} emptyRows={21-currentPageLineItems.length}/>
        </>);
    } else return(<></>)
    };
      const RenderSeller=()=>{

        return(
            <div className="vendorSummary" style={{maxWidth:"max-content", margin: "0",display:"inline-flex",verticalAlign:"top"}}>  
            <div> {renderImageStatus()}</div>
            <div className="poSummaryText" style={{maxWidth:"max-content"}}>
                <span className="vendorSummaryCategory" style={{color: "#000", textTransform: "capitalize"}}>Seller</span>
                <span className="poSummaryValue">{`Company: ${soDetail.orgName}`}</span>
                <span className="poSummaryValue">{`Plant: ${soDetail.plantName}`}</span>
                <span className="poSummaryValue">{`${soDetail.plantCity}-${soDetail.plantZipCode}, ${soDetail.plantState}`}</span>
                <span className="poSummaryValue">{`GSTIN/UIN: ${soDetail.plantGstNo}`}</span>
            </div>
        </div>
        )
      };
      const renderSummaryInfo = () => {
        if (soDetail !== null) {
            return (
                <React.Fragment>
                    <table  className='innerTable' style={{width:"100%",height:"100%"}}>
                    <tbody>
                        <tr>
                            <td>
                                <span className="vendorSummaryValue" style={{ textTransform: "capitalize" }}>So No:</span><br></br>
                                <span className="vendorSummaryCategory">{soDetail.soNo}&nbsp;</span>
                            </td>
                            <td >
                                <span className="vendorSummaryValue">So Date : </span><br></br>
                                <span className="vendorSummaryCategory">{soDetail.soDate}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="vendorSummaryValue">Plant Name</span><br/>&nbsp;
                                <span className="vendorSummaryCategory">{soDetail.plantName}</span><br/>&nbsp;
                            </td>
                            <td><span className="vendorSummaryValue">Credit Days</span><br/>
                            <span className="vendorSummaryCategory">{soDetail.customerCreditDays}</span><br/>&nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="vendorSummaryValue">Storage Name</span><br></br>&nbsp;
                                <span className="vendorSummaryCategory">{soDetail.storageName}</span><br/>&nbsp;
                            </td>
                            <td>
                                <span className="vendorSummaryValue">Expected Delivery Date</span><br />
                                <span className="vendorSummaryCategory">{soDetail.expectedDeliveryDate}</span><br/>&nbsp;
                            </td>
                        </tr>
                        <tr>
                        <td>
                            <span className="vendorSummaryValue">Transporter Name </span><br></br>
                            <span className="vendorSummaryCategory">{soDetail.transporterName}</span><br/>
                        </td>
                        <td>
                            <span className="vendorSummaryValue">Payment Mode</span><br />
                            <span className="vendorSummaryCategory">{soDetail.paymentMode}</span>
                        </td>
                        </tr>                       
                        <tr>
                            <td>
                                <span className="vendorSummaryValue">Dispatched Through</span><br></br>&nbsp;
                                <span className="vendorSummaryCategory">{soDetail.dispatchedThrough}</span>
                            </td>
                            <td>
                                <span className="vendorSummaryValue">Destination</span><br></br>&nbsp;
                                <span className="vendorSummaryCategory">{soDetail.destination}</span>
                            </td>
                        </tr>   
                    
                    </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };
    const RenderConsigneeAddress=({context,addressDetail ={}})=>{

        return(
            <div className="vendorSummary" style={{maxWidth:"max-content", margin: "0",display:"inline-flex",verticalAlign:"top"}}>
            <div className="poSummaryText">
                <span className="vendorSummaryCategory"  style={{color: "#000", textTransform: "capitalize"}}>
                    {`${context} to: ${addressDetail.customerName?.toUpperCase()}`}
                </span>
                <span className="vendorSummaryValue">
                    {`ID: ${addressDetail.customerId}`}
                </span>
                <span className="vendorSummaryValue">
                {addressDetail.customerAddress?.split(',').join(`,\n\n`)}, <br />
                    {addressDetail.customerPinCode !== null
                        ? `${addressDetail.customerCity}  - ${addressDetail.customerPinCode}`
                        : addressDetail.customerCity},
                        {addressDetail.customerState}
                </span>
                <span className="vendorSummaryValue">
                    {addressDetail.customerMobile !== null || addressDetail.customerPhone !== null ? `\nPhone: ` : null}
                    {addressDetail.customerMobile !== null ? addressDetail.customerMobile : null}
                    {addressDetail.customerPhone !== null ? `, ${addressDetail.customerPhone} ` : null}
                </span>
                <span className="vendorSummaryValue">
                    {addressDetail.customerEmail !== null ? `Email Id: ${addressDetail.customerEmail}` : null}
                </span>

                <span className="vendorSummaryValue">
                    {addressDetail.customerGstNo !== null ? `GSTIN/UIN: ${addressDetail.customerGstNo}` : null}
                </span>
                </div> 
            </div>
        )
      }
      const RenderSaleOrderDetailTable=()=>{

        return(
            <>
            <table className="invoiceBill" style={{width:"100%"}}>
                 <tbody>
                    <tr>   
                    <td>         
                        <RenderSeller />
                    </td>
                    <td rowSpan={2} style={{border:"none",padding:"0px",height:"100%"}}>
                    {renderSummaryInfo()}
                    </td>
                   </tr>
                    <tr>
                        <td><RenderConsigneeAddress context={"Bill"} addressDetail={soDetail}/></td>
                    </tr>
                    <tr>
                    <td>
                        <RenderConsigneeAddress context={"Ship"} addressDetail={deliveryAddressDetail}/>
                    </td>    
                    <td style={{verticalAlign:"top",fontSize:"12px"}}>
                        <b style={{fontSize:"14px"}}>Instruction</b> 
                            <br/>
                            {soDetail.salesInstruction}
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    {soDetail.soStatus === "Declined" ? (
                        <div style={{ marginTop: "1rem" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {soDetail.declineReason}
                        </div>
                    ) : null}
                </>
        )
      };

      const RenderBankDetails=()=>{
        return bankDetailsList.map((bank,ind) => {
            return (
              <div key={ind}>
                  <div className="small-font vendorSummaryValue">
                  {`A/c Holder Name`} &emsp;{`: ${bank?.accountHolderName}`}
                  </div>
                  <div className="small-font vendorSummaryValue">
                      {`Bank Name`} &emsp;&emsp;&emsp;&emsp;{`: ${bank?.bankName}`}
                  </div>
                  <div className="small-font vendorSummaryValue">
                      {`A/c No.`} &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{`: ${bank?.accountNumber}`}
                  </div>
                  <div className="small-font vendorSummaryValue" style={{color: "#000", textTransform: "capitalize"}}>
                      {`Branch & IFS Code `} &ensp;{`: ${bank?.branch}`}&ensp;&amp;&ensp;{`${bank?.ifscCode}`}
                  </div><br></br>
              </div>
            );
          });
      }
      const lineItemParams = isLogged.showHideWeight ? ["itemName", "from", "to", "netWeight", "grossWeight", "hsnCode","GST", "quantityuom", "unitPrice", "uom","discount","amount"] :
      ["itemName","hsnCode","GST","quantityuom", "unitPrice","uom","discount","amount"];
const RenderTable = ({currentPageLineItems,pageNumber}) => {
        return (
            <>
            <table className="innerTable" style={{width:"100%",height:"90%"}}>
                <thead>
                    <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                        <td>S.No.</td>
                        <td>Description of Goods</td>
                         {isLogged.showHideWeight ? (
                    <>
                      <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td>
                    </>
                  ) : null}
                        <td>HSN/SAC No</td>
                        <td>GST (%)</td>
                        <td>Quantity</td>
                        <td>Unit Price</td>
                        <td>Per</td>
                        <td>Dis(%)</td>
                        <td>Amount</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows  currentPageLineItems={currentPageLineItems} pageNumber={pageNumber}  tab="contact" />
                    
                  { pageNumber===lineItemChunk.length ? 
                    <>
                    
                    {mainItemsTaxRow.map((tax)=>RenderTaxRow(tax))}
                   <RenderEmptyRows columns={lineItemParams} emptyRows={6}/>
                            <tr>
                                <td colSpan={lineItemParams.length}>
                                    <div className="small-font">Other Charges</div>
                                    <div className="small-font">Transport Charges </div>
                                 </td>
                                <td>
                                        <div className="medium-font  align-right">{getIndianCurrency(soDetail.otherCharges)}</div>
                                        <div className="medium-font  align-right">{getIndianCurrency(soDetail.transportCharges)}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={lineItemParams.length}> 
                                        <b>Total Amount</b>
                                </td>
                                <td className="align-right">
                                    <div className="poNetTotalAmount align-right" style={{color: "#000"}}><b>{getIndianCurrency(Math.round(soDetail["totalAmount"]))}</b></div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={lineItemParams.length+1}>    
                                    <div className="medium-font">Amount Chargeable(in Words):&emsp; <b>INR&ensp; </b>{amountInWords(soDetail.totalAmount?.split('.')[0])}</div>
                                </td>
                        </tr> 
                        </>
                        : <tr className="invoiceDetailContactsTableRows">
                            <td style={{textAlign:"right"}}colSpan={lineItemParams.length + 1}>continued to Page Number {pageNumber+1}</td>
                          </tr>}
                </tbody>
            </table>
            <div style={{width: "100%",display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                            <div>
                                    <span className="small-font vendorSummaryRole" style={{color: "#000"}}><b><u>Declaration:</u></b></span>
                                    <div className="medium-font poSummaryValue" style={{maxWidth:"400px",wordBreak:"break-word"}}>We Declare that this Profroma Invoice shows the actual price of goods described and that all particulars are true and correct</div>
                            </div>
                            <div style={{fontSize:"12px",height:"200px",width:"300px"}}>
                            <span className="small-font vendorSummaryRole" style={{color: "#000"}}><b>Bank Details</b></span>
                            {RenderBankDetails()}
                            </div>
                    </div>
            {pageNumber===lineItemChunk.length ? 
            <>
                    <div  style={{width:"100%",position:"absolute",bottom:"15px"}}>
                            <span className="medium-font vendorSummaryRole" style={{width:"50%",color: "#000", textAlign: "left", marginBottom: "10px"}}>Customer's Seal and Signature</span>
                            <span style={{width: "50%"}}>
                                <div className="medium-font poSummaryValue" style={{color: "#000", textAlign: "right", marginTop: "5px", marginRight: "5px"}}><b>Authorized Signatory</b></div>
                            </span>
                    </div>
                    </> : null}
            </>
        );
};
const RenderTaxPageDetails=()=>{
    return (
        <table className="invoiceBill" style={{width:"100%"}}>
        <tbody>
           <tr>
               <td style={{verticalAlign:"top",textAlign:"left",fontSize:"14px",borderRight:"none"}}>So No : {soDetail.soNo}</td>
               <td style={{textAlign:"center",border:"none"}}> <RenderSeller /></td>
               <td style={{verticalAlign:"top",textAlign:"right",fontSize:"14px",borderLeft:"none"}}>So Date : {soDetail.soDate}</td>
           </tr>
           <tr>
               <td colSpan={3} style={{textAlign:"center"}}><RenderConsigneeAddress context={"Bill"} soDetail={soDetail}/></td>
           </tr>
       </tbody>
   </table>
    )
}
const taxTableParams= IGST ?["hsnCode","amount", "GST","IGSTAmount","taxAmount"]: ["hsnCode","amount","CGST","CGSTAmount", "SGST","SGSTAmount","taxAmount"]
const RenderTaxTable=({rows,pageNumber})=>{
    return (
     <table className="innerTable" style={{width:"100%", color: "#000"}}>
                    <thead>
                        <tr className="invoiceDetailContactsTableHeader" style={{color:"#000"}}>
                            <td rowSpan="2" style={{position: "relative"}}>S.No.</td>
                            <td rowSpan="2" style={{position: "relative"}}>HSN/SAC NO</td>
                            <td rowSpan="2" style={{position: "relative"}}>Taxable Value</td>
                            {IGST ? null :<td colSpan={2} >Central Tax</td>}
                            <td colSpan={2}>{IGST ? "IGST":"State Tax"}</td>
                            <td rowSpan="2" style={{position: "relative"}}>Total Tax Amount</td></tr>
                        <tr className="invoiceDetailContactsTableHeader" style={{color:"#000"}}>
                            {IGST ? null : <><td>Rate(%)</td><td>Amount</td></>}
                            <td >Rate(%)</td>
                            <td >Amount</td></tr>
                    </thead>
                    <tbody> 
            {rows.map((row,i)=>{
            return(
                <tr className="invoiceDetailContactsTableRows" key={i}>
                    <td style={{borderBottom:"none",borderTop:"none",textAlign:"align-right"}}>{((pageNumber-1)*16)+i+1}</td>
                    { 
                    taxTableParams.map((column,ind)=>{
                        if (column === "amount" || column === "CGSTAmount" || column ==='IGSTAmount'
                        || column === "taxAmount" || column === "SGSTAmount" ) {
                            return (<td className="align-right" style={{borderBottom:"none",borderTop:"none"}} key={ind}>{getIndianCurrency(row[column])}</td>);
                        } else {
                            return (<td className="align-left" style={{borderBottom:"none",borderTop:"none"}} key={ind}>{row[column]}</td>);
                        }
                    })
                    }
                </tr>
            );
        })}
        <RenderEmptyRows columns={taxTableParams} emptyRows={7}/>
        {pageNumber === lineItemChunk.length ?
        <>
        <tr className="invoiceDetailContactsTableRows">
            <td>&nbsp;</td><td className="align-right">Total</td><td className="align-right">₹{(soDetail["totalAmount"] -soDetail["GSTAmount"]).toFixed(2)}</td>
            {IGST ? null :<><td>&nbsp;</td><td className="align-right">₹{soDetail["CGSTAmount"]}</td></>}
            <td>&nbsp;</td><td className="align-right">₹{IGST ?soDetail["IGSTAmount"] :soDetail["SGSTAmount"]}</td>
            <td className="align-right">₹{soDetail["GSTAmount"]}</td>
        </tr>
        <tr className="invoiceDetailContactsTableRows">
            <td colSpan={8}>
                    <div className="medium-font">Taxed Amount(in Words): 
                    <b>&emsp;INR&ensp; </b>{amountInWords(soDetail["GSTAmount"]?.toString())}</div>
            </td>
        </tr>
        </> : 
        <tr className="invoiceDetailContactsTableRows">
           <td colSpan={8} className="align-right">continued to Page Number {pageNumber+1}</td> 
            </tr>}
            </tbody>
     </table>
        );
}
      return(
        <div className="detailsPageWrapper">
        { //Invoice Pages
        lineItemChunk.length > 0 ?
        lineItemChunk.map((currentPage,i)=>{                   
                    return(
                    <div className="detailsPageContainer" key={i}>
                        <div className="invoicePageTitle">Sales Order/Proforma Invoice</div>
                        <div style={{border:"0.25px solid black",minHeight:"1100px",position:"relative"}}>
                            <RenderSaleOrderDetailTable />
                            <RenderTable currentPageLineItems={currentPage} pageNumber={i+1}/>
                        </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <span style={{fontSize: "9px"}}>SUBJECT TO {soDetail.plantCity?.toUpperCase()} JURISDICTION</span>
                            </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <span style={{fontSize: "9px"}}>This is a Computer Generated Invoice</span>
                            </div>
                    </div>
                    )
                    }) : null
        }
       { 
       //Tax Analysis Pages
       lineItemChunk.length > 0 ? 
       lineItemChunk.map((currentTaxPage,index)=>(
        <div className="detailsPageContainer" key={index}>
            <div className="invoicePageTitle">Sales Order/Proforma Invoice</div>
            <div className="medium-font"><center>(Tax Analysis)</center></div>
            <div style={{border:"1px solid black",minHeight:"1100px",position:"relative"}}>
                <RenderTaxPageDetails />
                <RenderTaxTable rows={currentTaxPage} pageNumber={index+1}/>  
                 {  index+1===lineItemChunk.length ?    
                  <div  style={{width:"95%",position:"absolute",bottom:"100px",margin:"10px 20px 0px 0px"}}>
                    <span >
                        <div className="medium-font poSummaryValue" style={{color: "#000", textAlign: "right"}}><b>Authorized Signatory</b></div>
                    </span>
            </div>:null}
          </div>
        </div>)) : null}
    </div>
      )
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),

        // soInvoiceCreate: (invoiceCreateId) => dispatch(soInvoiceCreateId(invoiceCreateId)),
        // soEditInvoice: (editInvoiceId) => dispatch(soEditInvoiceId(editInvoiceId)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(ProformaInvoice);